@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap");
:root {
  --bg: #e6e5eb;
  --main: #18eb85;
  --main2: #267f6e;
  --shadow: 10px 10px 15px -10px rgb(24, 235, 133);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body {
  font-family: "Raleway", sans-serif;
  background: var(--bg);
}
.home-inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  min-height: 100vh;
  place-content: center;
  align-items: center;
}
.home-inner span {
  text-transform: uppercase;
  display: block;
  margin: 30px 0px;
  text-align: center;
  color: red;
  font-weight: 600;
  font-size: 12px;
}
.home-inner video {
  width: 100%;
}
.home-inner h2 {
  text-transform: capitalize;
  margin-bottom: 30px;
  font-weight: 600;
  color: var(--main2);
  font-size: 50px;
}
.home-inner p {
  margin: 20px 0px;
  margin-top: 40px;
  font-weight: 600;
}
.home-inner a {
  text-decoration: none;
  color: #fff;
  background: var(--main);
  padding: 15px 30px;
  text-transform: capitalize;
  border-radius: 10px;
}

.lint-to-mine {
  margin-top: 80px;
}
.mine {
  min-height: 100vh;
  position: relative;
}
.mine-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}
.mine::before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #267f6e;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.5;
}
.mine-content {
  position: relative;
  z-index: 3;
}
.mine-aprrove {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}
.mine-aprrove a {
  display: flex;
  gap: 10px;
}
.note {
  font-weight: 700;
  display: block;
  color: red;
}
.btnsss {
  display: flex;
  justify-content: space-between;
}
.btnsss img {
  object-fit: contain;
}
.btnsss a {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
}
.mine-aprrove img {
  width: 30px;
}
.mine-aprrove button,
.mine-aprrove a {
  text-transform: capitalize;
  background: transparent;
  color: #fff;
  border: none;
  border: 1px solid var(--main);
  padding: 10px 30px;
  border-radius: 10px;
  box-shadow: var(--shadow) !important;
  transition: 0.3s ease-in-out;
  text-decoration: none;
}
.mine-aprrove a:last-child {
  margin-left: 10px;
}
.mine-aprrove button:hover {
  background: var(--main);
}
.mine-info-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  gap: 50px;
  margin: 40px 0px;
}
.mine-info {
  display: flex;
  align-items: center;
  box-shadow: var(--shadow);
  gap: 30px;
  border-radius: 10px;
  padding: 10px;
}
.mine-info p {
  margin-bottom: 0;
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
}
.mine-info span {
  display: block;
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
}
.mine-info img {
  width: 100px;
  object-fit: contain;
}
.usdt-info {
  background: #fff;
  box-shadow: var(--shadow);
  padding: 20px;
  width: 50%;
  border-radius: 10px;
}
.center-usdt-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
}
.usdt-info input {
  width: 100%;
  margin: 10px 0px;
  padding: 8px;
  outline: none;
  border: none;
  border-bottom: 2px solid var(--main2);
}
.start-btn {
  width: 100%;
  margin: 15px 0px;
  padding: 10px;
  background: var(--main2);
  color: #fff;
  text-transform: capitalize;
  border: none;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}
.start-btn:hover {
  color: var(--main2);
  background: none;
  border: 1px solid var(--main2);
}
.sub-btn {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.widthdraw-btn,
.hire-btn {
  border: 2px solid var(--main2);
  color: #000;
  background: none;
  text-transform: capitalize;
  padding: 10px;
  border-radius: 5px;
}
.widthdraw-btn:hover,
.hire-btn:hover {
  background: var(--main2);
  color: #fff;
  transition: 0.3s ease;
}
.user-info {
  display: flex;
  gap: 30px;
  align-items: center;
  margin: 30px 0px;
}
.user-info img {
  width: 50px;
}
.user-info p {
  margin-bottom: 0;
  font-weight: 700;
  text-transform: capitalize;
}
.usdt-bonus {
  text-align: center;
  margin: 10px 20px 20px;
}
.usdt-span {
  display: block;
  font-weight: bold;
  text-align: center;
  margin: 0px 0px 20px;
  word-wrap: break-word;
}
.copy-btn {
  text-transform: capitalize;
  padding: 8px 30px;
  color: #fff;
  border: none;
  background-color: var(--main2);
  box-shadow: var(--shadow);
  border-radius: 10px;
}
.center-copy-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.steps {
  margin-bottom: 20px;
}
.steps span {
  display: block;
  font-size: 14px;
}
.steps p {
  margin-bottom: 4px;
  font-weight: 500;
}
.mine-det{
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  align-items: flex-end;
}
.mine-det .mine-info {
  width: fit-content;
  border:  1px solid var(--main);
  margin: 10px 0px;
}
.mine-det .mine-info p{
  padding: 10px 30px;
}
@media screen and (max-width: 768px) {
  .mine-aprrove {
    flex-direction: column;
    gap: 40px;
  }
  .usdt-span {
    width: 100%;
    display: block;
  }
  .usdt-info {
    width: 100%;
  }
  .home-inner {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .home-inner p {
    margin: 10px 0px;
    text-align: center;
  }
  .home-inner h2 {
    margin-top: 80px;
    text-align: center;
  }
  .lint-to-mine {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
}
